import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import VueGeolocation from 'vue-browser-geolocation'

Vue.config.productionTip = false
Vue.use(VueGeolocation)

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    var username = localStorage.username
    if (username !== undefined) {
      if (username !== '') {
        next('/map')
      }
    }
  } else if (to.path !== '/map' && to.path !== '/nations' && to.path !== '/schedule' && to.path !== '/missions') {
    next('/')
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
