import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/map',
      name: 'map',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "map" */ './views/Map.vue'),
      meta: { layout: 'navbar' }
    },
    {
      path: '/nations',
      name: 'nations',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "map" */ './views/NationListPage.vue'),
      meta: { layout: 'navbar' }
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: () => import('./views/SchedulePage.vue'),
      meta: { layout: 'navbar' }
    },
    {
      path: '/missions',
      name: 'missions',
      component: () => import('./views/Missions.vue'),
      meta: { layout: 'navbar' }
    }
  ]
})
