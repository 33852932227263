<template>
  <v-app>
    <v-navigation-drawer v-if="signedIn" v-model="navbarVisible" app>
      <v-toolbar color="#f1b434">
        <v-toolbar-side-icon @click.stop="navbarVisible = !navbarVisible" />
        <v-toolbar-title>
          <span>{{ name }}</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-list>
        <div v-for="page in pages" :key="page.name">
          <v-list-tile
            :to="{ path: page.route }"
            @click.stop="navbarVisible = false"
            :active-class="'selecteditem'"
          >
            <v-list-tile-action>
              <v-icon>{{ page.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ page.name }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-divider />
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar app color="white" tabs>
      <v-toolbar-side-icon
        v-if="signedIn && !navbarVisible"
        @click.stop="navbarVisible = true"
      />
      <router-link
        v-if="$vuetify.breakpoint.mdAndUp"
        style="height: 40px"
        to="/map"
        tag="img"
        :src="require('@/assets/futflogga.png')"
      />
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-toolbar-title class="headline text-uppercase">
        <span>Fubrundan HT22</span>
      </v-toolbar-title>
      <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-btn flat icon v-if="signedIn" @click="onSignOut" color="f1b434">
        <v-icon>exit_to_app</v-icon></v-btn
      >
    </v-toolbar>
    <v-content>
      <router-view v-if="signedIn" />

      <v-container v-if="!signedIn">
        <v-card>
          <v-card-title primary-title>
            <span class="headline">Vad heter du?</span>
          </v-card-title>

          <v-card-text>
            <v-form
              v-model="valid"
              ref="form"
              @submit="onSignin()"
              onSubmit="return false"
            >
              <v-flex xs12>
                <v-text-field
                  label="Namn: (använd inget personligt)"
                  required
                  v-model="name"
                  outline
                  :rules="[(n) => !!n || 'Obligatorisk!']"
                  :error-messages="errorMessages"
                  @click="errorMessages = []"
                ></v-text-field>
                <v-checkbox
                  label="Jag loggar in för första gången"
                  v-model="newLogin"
                  color="#f1b434"
                  @click.native="errorMessages = []"
                >
                </v-checkbox>
                <v-checkbox
                  label="Jag godkänner att IT-gruppen varsamt och ömt behandlar dina positionsuppgifter."
                  v-if="newLogin"
                  required
                  color="#f1b434"
                  :rules="[(v) => !!v || 'Du måste såklart godkänna, pajas!']"
                  v-model="agreed"
                >
                </v-checkbox>
                <v-btn
                  @keyup.enter="onSignin()"
                  :disabled="!valid"
                  round
                  @click="onSignin()"
                  color="#f1b434"
                  submit
                >
                  Logga in
                </v-btn>
              </v-flex>
            </v-form>
          </v-card-text>
          <v-layout row>
            <v-flex xs6>
              <v-img width="100%" position="left" src="img/icons/kvlogga.png" />
            </v-flex>
            <v-flex xs6>
              <v-img
                height="100%"
                position="right"
                src="img/icons/Cybertuppen.png"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-container>
    </v-content>
  </v-app>
</template>

<style lang="sass">
@import '@/sass/variables.sass'
main
  font-family: $body-font-family
  .headline, .v-toolbar__title
    font-family: $heading-font-family !important
  .selecteditem
    color: #f1b434
</style>

<script>
import axios from 'axios'

export default {
  name: 'App',
  data () {
    return {
      pages: [
        {
          name: 'Uppdrag',
          route: '/missions',
          icon: 'check_box'
        },
        {
          name: 'Karta',
          route: '/map',
          icon: 'map'
        },
        {
          name: 'Nationer',
          route: '/nations',
          icon: 'info'
        },
        {
          name: 'Schema',
          route: '/schedule',
          icon: 'access_time'
        }
      ],
      navbarVisible: false,
      valid: false,
      name: '',
      signedIn: false,
      newLogin: false,
      agreed: false,
      errorMessages: []
    }
  },
  methods: {
    onSignin: function () {
      if (this.valid) {
        if (this.newLogin) {
          axios.get('https://api.futf.se/api/user/read.php')
          .then((result) => {
            // Check if the name exists in the JSON object
            // console.log(result.data)
            let exists = false
            for (let i = 0; i < result.data.length; i++) {
              let obj = result.data[i]
              if (this.name == obj.username) {
                exists = true
                break
              }
            }
            if (!exists) {
              localStorage.username = this.name
              axios.post('https://api.futf.se/api/user/create.php', {
                username: this.name,
                latitude: '0',
                longitude: '0'
              })
              .then(response => {
                this.signedIn = true
                this.$router.push('/map')
              })
              .catch(error => {
                console.log(error);
              })
              // this.signedIn = true
              // this.$router.push('/map')
            } else {
              this.errorMessages.push('Namnet används redan!')
            }
          })
          .catch((error) => {
            console.log(error)
          })
        } else {
          axios.get('https://api.futf.se/api/user/read.php')
          .then((result) => {
            // Check if the name exists in the JSON object
            // console.log(result.data)
            let exists = false
            for (let i = 0; i < result.data.length; i++) {
              let obj = result.data[i]
              if (this.name == obj.username) {
                exists = true
                break
              }
            }
            if (exists) {
              localStorage.username = this.name
              this.signedIn = true
              this.$router.push('/map')
            } else {
              this.errorMessages.push('Namnet används inte!')
            }
          })
        }
      }
    },
    onSignOut: function () {
      localStorage.username = ''
      this.signedIn = false
      this.$router.push('/')
    }
  },
  mounted: function () {
    var username = localStorage.username
    localStorage.version = 'HT19'
    if (username !== undefined) {
      if (username !== '') {
        this.name = username
        this.signedIn = true
      }
    }
  }
}
</script>
